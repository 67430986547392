import Link from 'next/link'
import { NextPage } from 'next'
import { Button } from '@components'

const FourOhFour: NextPage = props => (
  <>
    <main className="flex h-screen w-full flex-col items-center justify-center bg-themeGray-dark3">
      <h1 className="text-9xl font-extrabold tracking-widest text-white">
        404
      </h1>
      <div className="absolute rotate-12 rounded bg-primary-light px-2 text-sm text-white">
        Page Not Found
      </div>
      <Link href="/" className="mt-5">
        <Button>Go Home</Button>
      </Link>
    </main>
  </>
)

export default FourOhFour
